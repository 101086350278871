import { gsap } from 'gsap'

export type AnimationStates = { initial: gsap.TweenVars; final: gsap.TweenVars }

export const mobileMediaQuery = '(max-width: 1023px)'
export const desktopMediaQuery = '(min-width: 1024px)'

export const gsapImageSequencer = (config: {
  urls: string[]
  onUpdate?: VoidFunction
  canvas: HTMLCanvasElement
  scrollTrigger: gsap.AnimationVars['scrollTrigger']
}) => {
  let playhead = { frame: 1 }
  let ctx = config.canvas.getContext('2d')
  let onUpdate = config.onUpdate
  let images: HTMLImageElement[]

  let updateImage: GlobalEventHandlers['onload'] = function (this) {
    const img = images[Math.round(playhead.frame)]

    if (ctx && img) {
      // Clear to preserve alpha channel
      ctx.clearRect(0, 0, config.canvas.width, config.canvas.height)

      // Fit whole canvas regardless of aspect ratio
      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height,
        0,
        0,
        config.canvas.width,
        config.canvas.height
      )
    }
    onUpdate?.call(this)
  }

  images = config.urls.map((url, i) => {
    let img = new Image()
    img.src = url
    i || (img.onload = updateImage)
    return img
  })

  return gsap.to(playhead, {
    frame: images.length - 1,
    ease: 'none',
    onUpdate: updateImage,
    scrollTrigger: config.scrollTrigger,
  })
}
